<template>
    <div>
        <!-- <div style="text-align:center;" class="font16-black">请选择需要发布的渠道</div> -->
        <div style="min-height: 110px;">
            <div v-if="type == 1" class="cource flex flex-justify-around">
                <div v-for="(item,idx) in list" :key="idx" @click="changeShop(item.id,idx)" :class="{'cur':shopIndex == idx}" class="cource-item">
                    <div class="flex flex-align-center flex-pack-center">
                        <i v-if="item.type == 0" class="iconfont icon-Shape"></i>
                        <i v-if="item.type == 1" class="iconfont icon-Shape"></i>
                        <i v-if="item.type == 2" class="iconfont icon-pinduoduo"></i>
                    </div>
                    <div class="font14-grey1 ellipsis">{{item.name}}</div>
                </div>
                <!-- <div class="cource-item">
                    <div class="flex flex-align-center flex-pack-center"><i class="iconfont icon-Shape"></i></div>
                    <div class="font14-grey1 ellipsis">aa</div>
                </div>
                <div class="cource-item">
                    <div class="flex flex-align-center flex-pack-center"><i class="iconfont icon-Shape"></i></div>
                    <div class="font14-grey1 ellipsis">bb</div>
                </div> -->
            </div>
            <div v-if="type == 2" style="text-align: center;font-size: 16px;">
                您还没有绑定店铺，请先去绑定
            </div>
        </div>
        <div class="flex flex-justify-around">
            <div><el-button size="small" @click="Hide()">取消</el-button></div>
            <div>
                <el-button v-if="type == 1" size="small" type="warning" @click="submit()">确定</el-button>
                <el-button v-if="type == 2" size="small" type="warning" @click="gotolink('/sale/shop/list')">绑定店铺</el-button>
            </div>
        </div>
    </div>
</template>
<script>
    import { query } from '@/service/sale';
    export default {
        model: {
            prop: 'productID',
            event: 'change',
        },
        props: ['productID'],
        data() {
            return {
                list:[],
                shopIndex:0,
                shopId:null,
                type:null,
            }
        },
        mounted() {
            this.refreshRows();
            console.log(this.productID);
        },
        methods: {
            gotolink(path){
                this.$router.push(path);
            },
            changeShop(id,index) {
                this.shopIndex = index;
                this.shopId = id;
            },
            refreshRows() {
                query({
                    shop:{
                        list:{}
                    }
                }).then(resp=>{
                    if (resp.shop.list.rows && resp.shop.list.rows.length>0) {
                        this.list=resp.shop.list.rows;
                        // this.total=resp.shop.list.count;
                        this.shopId = this.list[0].id;
                        console.log(this.list);
                        this.type = 1;
                    } else {
                        this.type = 2;
                    }
                }).catch(err => {
                    this.$message.error(err.message);
                });
            },
            Hide() {
                this.$emit('hide');
            },
            submit() {
                this.$emit('hide');
                this.$router.push('/sale/shop/item/add/'+this.shopId+'/'+this.productID);
            }
        },
    }
</script>
<style scoped>
    .ellipsis {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
    }
    .cource {
        margin: 50px auto 40px;
    }
    .cource .cource-item {
        width: 60px;
        text-align: center;
        margin-right: 50px;
        cursor: pointer;
    }
    .cource .cource-item:last-child {
        margin-right: 0;
    }
    .cource-item>div:first-child {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        box-sizing: border-box;
        border: 1px solid #FF5800;
        margin-bottom: 10px;
    }
    .cource-item>div:first-child i {
        font-size: 28px;
        color: #FF5800;
    }
    .cource .cource-item.cur>div:first-child {
        background:rgba(255,88,0,0.2);
    }
    .cource .cource-item.cur>div:last-child {
        color: #FF5800;
    }

</style>