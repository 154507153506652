<template>
  <div class="good-swiper">
    <div class="prev" @click="prev()">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="inner" ref="inner">
      <div class="wrap" ref="wrap" :style="{transform: 'translateX(' + posX + 'px)'}">
        <Comp3 v-for="(item,idx) in items" :key="idx" :item="item" />
      </div>
    </div>
    <div class="next" @click="next()">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import Comp3 from "./Comp3.vue";
export default {
  components: {
    Comp3
  },
  props: {
    items: Array
  },
  data() {
    return {
      posX: 0,
      index: 0
    };
  },
  mounted() {
    this.inner = this.$refs.inner;
    this.wrap = this.$refs.wrap;
    this.innerW = this.inner.offsetWidth;
  },
  methods: {
    prev() {
      if (this.index === 0) {
        return;
      }
      this.index--;
      this.posX = -(this.innerW + 33) * this.index;
    },
    next() {
      if (this.index === Math.ceil(this.items.length / 3) - 1) {
        return;
      }
      this.index++;
      this.posX = -(this.innerW + 33) * this.index;
    }
  }
};
</script>

<style scoped lang="less">
.good-swiper {
  width: 733px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .prev,
  .next {
    width: 16px;
    height: 75px;
    background: rgba(245, 245, 245, 1);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    i {
      color: #707070;
      font-size: 20px;
    }
  }
  .prev {
    margin-left: 20px;
  }
  .next {
    margin-right: 20px;
  }
  .inner {
    width: 633px;
    overflow: hidden;
  }
  .wrap {
    display: flex;
    flex-wrap: nowrap;
    transition: transform 0.3s;
    .good {
      margin-right: 33px;
    }
  }
}
</style>