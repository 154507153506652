<template>
    <div class="spec">
        <div v-if="spec && spec.length>0" class="check-guige">
            <div v-for="(item,idx) in spec" :key="idx" class="flex">
                <label>{{item.name}}</label>
                <div class="flex-1">
                    <div v-for="(m, i) in item.items" :key="i" class="item"
                    :class="[m.isShow?'':'noneActive',subIndex[idx] == i?'active':'']"
                    @click="specificationBtn(m,idx,$event,i)">{{m.name}}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { uniqBy } from "lodash";
    import { getCatDetail } from '@/service/item';
    export default {
        props: ['skus','catId'],
        data() {
            return {
                spec:[],
                selectArr: [], //存放被选中的值
                shopItemInfo: [], //存放要和选中的值进行匹配的数据
                subIndex: [], //是否选中 因为不确定是多规格还是但规格，所以这里定义数组来判断
                sku:null, // 选中规格对应的sku
            }
        },
        computed: {},
        watch: {
            skus:{
                handler() {
                    this.handleSku();
                    // console.log("skus",this.skus);
                },
                deep: true
            }
        },
        created () {
            this.handleSku();
        },
        methods: {
            handleSku() {
                console.log("skus",this.skus);
                let _this = this;
                let flag = false;
                this.skus.map((n,idx) => {
                    n.difference=[];
                    n.difference.push(n.material.name);
                    n.difference.push(n.color.name);
                    if (n.catAttrValues && n.catAttrValues.length>0) {
                        n.catAttrValues.forEach(e => {
                            if (e.value) {
                                n.difference.push(e.value);
                            }
                        })
                    }
                    n.difference = n.difference.join(",");
                    flag = true;
                });
                if (flag) {
                for (let i in this.skus) {
                    this.shopItemInfo[_this.skus[i].difference] = _this.skus[i]; //修改数据结构格式，改成键值对的方式，以方便和选中之后的值进行匹配
                }
                // console.log(this.shopItemInfo);
                }
                this.checkItem();
                this.getCat();
            },
            getCat() {
                let spec = [];// 最终规格数组
                let obj = { "name":"材质","items":[] }; // 写死的规格
                let obj1 = { "name":"颜色","items":[] }; // 写死的规格
                let attrs = [];// 存放临时数据
                let attrItems = [];// 整理想要结构的动态规格数据
                let attrsName = [];// 存放动态规格名称
                this.skus.map((n,idx) => {
                    obj.items.push({ ...n.material });
                    obj1.items.push({ ...n.color });
                    if (n.catAttrValues && n.catAttrValues.length>0) {
                        n.catAttrValues.forEach((e)=> {
                            if (e.name) {
                                attrs.push(e);
                            }
                        })
                    }
                });
                attrsName = uniqBy(attrs, k => k.name);
                attrsName.forEach((e)=> {
                    attrItems.push({name:e.name,"items":[]});
                })
                attrs = uniqBy(attrs, k => k.value);
                attrItems.forEach((n)=> {
                    attrs.forEach((e)=> {
                        if (e.name == n.name) {
                            n.items.push({id:e.id,name:e.value});
                        }
                    })
                })
                // console.log("attrs",attrItems);
                obj.items = uniqBy(obj.items, k => k.id);
                spec.push(obj);
                obj1.items = uniqBy(obj1.items, s => s.id);
                spec.push(obj1);
                attrItems.forEach(e => {
                    spec.push(e);
                });
                this.spec = spec;
                console.log("spec",this.spec);
            },
            specificationBtn: function (item, n, event, index) {
                if (this.selectArr[n] != item.name) {
                    this.selectArr[n] = item.name;
                    this.subIndex[n] = index;
                } else {
                    this.selectArr[n] = "";
                    this.subIndex[n] = -1; //去掉选中的颜色
                }
                this.checkItem();
                let a = this.selectArr.join(',');
                // console.log(a);
                if (this.shopItemInfo[a]) {
                    this.sku = this.shopItemInfo[a];
                } else {
                    this.sku = null;
                }
                this.$emit("skuItem",this.sku);
                // console.log(this.selectArr);
                // console.log(this.subIndex);
                // console.log(this.sku);
            },
            checkItem: function () {
                let self = this;
                let option = self.spec;
                let result = []; //定义数组存储被选中的值
                for (let i in option) {
                    result[i] = self.selectArr[i] ? self.selectArr[i] : '';
                }
                for (let i in option) {
                    let last = result[i]; //把选中的值存放到字符串last去
                    for (let k in option[i].item) {
                        result[i] = option[i].item[k].name; //赋值，存在直接覆盖，不存在往里面添加name值
                        option[i].item[k].isShow = self.isMay(result); //在数据里面添加字段isShow来判断是否可以选择
                        
                    }
                    result[i] = last; //还原，目的是记录点下去那个值，避免下一次执行循环时避免被覆盖

                }
                self.$forceUpdate(); //重绘
            },
            isMay: function (result) {
                for (let i in result) {
                    if (result[i] == '') {
                        return true; //如果数组里有为空的值，那直接返回true
                    }
                }
                console.log("result",result);
                return this.shopItemInfo[result].prices.length<1 ? false : true; //匹配选中的数据的库存，若不为空返回true反之返回false
            },

        }
    }
</script>
<style scoped lang="less">
    .guige-block {
        padding-left: 35px;
        .check-guige {
        > div {
            margin-top: 11px;
            >label {
            font-size: 14px;
            color: #292929;
            line-height: 40px;
            width: 100px;
            text-align: left;
            }
            > div {
            .item {
                display: inline-block;
                padding: 0 24px;
                height: 30px;
                line-height: 30px;
                color: #016fff;
                border: 1px solid #016fff;
                border-radius: 4px;
                margin: 5px 12px;
                cursor: pointer;
                &.active {
                border: 1px solid rgba(1, 111, 255, 0.2);
                background: rgba(1, 111, 255, 0.2);
                }
                &.noneActive {
                    // border: 1px solid #808080;
                    // color: #808080;
                    // background-color: #ccc;
                    // pointer-events: none;
                }
            }
            }
        }
        }
  }
</style>