import { render, staticRenderFns } from "./Comp2.vue?vue&type=template&id=41e856ee&scoped=true&"
import script from "./Comp2.vue?vue&type=script&lang=js&"
export * from "./Comp2.vue?vue&type=script&lang=js&"
import style0 from "./Comp2.vue?vue&type=style&index=0&id=41e856ee&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41e856ee",
  null
  
)

export default component.exports