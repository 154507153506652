import { postToZCYShareServer, postToTGShareServer } from './common';
/**
 * 申请总代销商信息
 */
export async function applyGeneralAgent(obj){
    return await postToZCYShareServer('/apply-general-agent',obj);
}
/**
 * 获取总代销商信息
 */
export async function getGeneralAgent(){
    return await postToZCYShareServer('/get-general-agent');
}
/**
 * 获取总代销商微信小程序账号绑定二维码
 */
export async function getAgentWxqr(obj){
    return await postToZCYShareServer('/get-general-agent-bind-wxqr',obj);
}
/**
 * 列表旗下代理商
 */
export async function listAgent(obj){
    return await postToZCYShareServer('/list-agent',obj);
}
/**
 * 审核旗下代理商
 */
export async function statAgent(obj){
    return await postToZCYShareServer('/stat-agent',obj);
}
/**
 * 添加商品库
 */
export async function addFxProduct(obj){
    return await postToZCYShareServer('/add-fx-product',obj);
}
/**
 * 列表商品库
 */
export async function listFxProduct(obj){
    return await postToZCYShareServer('/list-fx-product',obj);
}
/**
 * 自定义分类编辑商品（销售公司分销）
 * @param {*} obj
 */
 export async function multiUpdateFxProduct(obj) {
    return await postToZCYShareServer("/multi-update-fx-product", obj);
  }
/**
 * 填写商品sku代理价格
 */
export async function setFxProductPrice(obj){
    return await postToZCYShareServer('/set-fx-product-price',obj);
}
/**
 * 上下架分销商品
 */
export async function statFxProduct(obj){
    return await postToZCYShareServer('/stat-fx-product',obj);
}
/**
 * 获取店铺或商品的二维码
 */
export async function getStoreWxqr(obj){
    return await postToZCYShareServer('/get-store-wxqr',obj);
}
/**
 * 移除分销商品
 */
export async function delFxProduct(obj){
    return await postToZCYShareServer('/del-fx-product',obj);
}

/* --------------------------------分销自有商品------------------------------------ */
// 销售公司-上传自有商品
export async function addFxSelfProduct(obj){
    return await postToZCYShareServer('/create-fx-sell-self-product',obj);
}
// 销售公司-修改自有商品
export async function updateFxSelfProduct(obj){
    return await postToZCYShareServer('/update-fx-sell-self-product',obj);
}
// 销售公司-分销商品详情
export async function fxDetailProduct(fx_product_id){
    return await postToZCYShareServer('/detail-product',{fx_product_id:fx_product_id});
}
// 销售公司-创建、修改、删除自有商品SKU
export async function updateFxSelfProductSku(obj){
    return await postToZCYShareServer('/update-fx-sell-self-product-sku',obj);
}

/* --------------------------------分销订单------------------------------------ */
// 销售公司-订购管理-列表
export async function saleOrderList(obj){
    return await postToTGShareServer('/front/sale/order/list',obj);
}
// 销售公司-订购管理-详情
export async function saleOrderGetId(id,obj){
    return await postToTGShareServer(`/front/sale/order/get/${id}`,obj);
}
// 销售公司-订购管理-修改价格
export async function orderEntrieSetPrice(id,obj){
    return await postToTGShareServer(`/front/sale/order/entrie/set_price/${id}`,obj);
}
// 销售公司-订购管理-确认订单
export async function orderConfirm(id){
    return await postToTGShareServer(`/front/sale/order/confirm/${id}`);
}
// 销售公司-订购管理-转采购订单
export async function orderToPurOrder(id,obj){
    return await postToTGShareServer(`/front/sale/order/to_pur_order/${id}`,obj);
}
// 销售公司-订购管理-设置运输方式/发货
export async function orderSetTranInfo(id,obj){
    return await postToTGShareServer(`/front/sale/order/set_tranInfo/${id}`,obj);
}
// 销售公司-订购管理-发货
export async function orderSend(id){
    return await postToTGShareServer(`/front/sale/order/send/${id}`);
}
